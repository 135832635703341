import React from 'react';
import {
  Container,
  WrapperHeader,
  TextHeader,
  WrapperIdDotColor,
  StatusDot,
  DateText,
  Divider,
  Content,
} from './styles';
import CardCommentsPeoples from '../CardCommentsPeoples';
import DividerCustom from '../Divider';

const CardComments = ({
  id = 'XXXXXX',
  date = '13/01/2023 - 12:20',
  status = 'canceled',
  allPassengers,
  driverData,
  ...rest
}) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'finished':
        return '#16C628';
      case 'canceled':
        return '#E50707';
      default:
        return '#a5a5a5';
    }
  };

  return (
    <Container {...rest}>
      <WrapperHeader>
        <WrapperIdDotColor>
          <StatusDot color={getStatusColor(status)} />
          <TextHeader>#{id}</TextHeader>
        </WrapperIdDotColor>
        <DateText>{date}</DateText>
      </WrapperHeader>
      <Divider />
      <Content>
      {allPassengers &&
          allPassengers.map((passData) => (
            <CardCommentsPeoples data={passData} />
          ))}
      </Content>
    </Container>
  );
};

export default CardComments;

