import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 42px;
  width: 340px;
  flex-shrink: 1;
  background-color: #eaeaea;
  padding: 1rem 0.5rem;
  border-radius: 8px;
`

export const WrapperPhotoNameRating = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const WrapperPhotoName = styled.div`
  align-items: center;
  flex-direction: row;
  display: flex;
`

export const TextName = styled.p`
  font-size: 15px;
  color: #000;
`

export const TextComment = styled.p`
  font-size: 12px;
  color: #4d4d4d;
  word-wrap: break-word;
`
