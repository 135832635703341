import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InputColor from 'react-input-color'

import { NButton, NCarousel, NInput, NSelector } from '~/components'
import { useApi, useApiEffect } from '~/hooks'
import { getRegister, setRegister } from '~/repositories/register'
import { verifyAppData } from '~/services/apps'
import { getCitiesByState, getStates } from '~/services/locations'
import formErrors from '~/utils/formErrors'

import { Wrapper, ToolBar, Container, SuggestionsContainer, SuggestionsList, SuggestionCircle, GenerateButton } from './styles'
import { fields, schema } from './props'

import { ReactComponent as Print1 } from './prints/Print-1.svg'
import { ReactComponent as Print2 } from './prints/Print-2.svg'
import { ReactComponent as Print3 } from './prints/Print-3.svg'
import { ReactComponent as Print4 } from './prints/Print-4.svg'
import { ReactComponent as Print5 } from './prints/Print-5.svg'
import { ReactComponent as Print6 } from './prints/Print-6.svg'
import { ReactComponent as Print7 } from './prints/Print-7.svg'
import { ReactComponent as Print8 } from './prints/Print-8.svg'
import { ReactComponent as Print9 } from './prints/Print-9.svg'

import appLogo from '../../../../assets/logo.png'

const printsList = [
  Print1,
  Print2,
  Print3,
  Print4,
  Print5,
  Print6,
  Print7,
  Print8,
  Print9,
]

const AppForm = () => {
  const save = getRegister()

  const navigate = useNavigate()
  const { state } = useLocation()
  const { request } = useApi()

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [colory, setColory] = useState('')
  const [suggestions, setSuggestions] = useState([])

  const {
    register,
    handleSubmit,
    formState,
    watch,
    control,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: save?.app,
    mode: 'all',
  })

  const name = watch('name')
  const color = watch('color')
  const UF = watch('state')

  const words = [
    'Jat', 'Mobi', 'Car', 'Run', 'Drive', 'Go', 'Quick', 'Zoom', 'Fast', 'Ride', 
    'Trip', 'Top', 'Flip', 'Task', 'Suif', 'Rit', 'Move', 'Way', 'Path', 'Jet', 
    'Track', 'Route', 'Swift', 'Loop', 'Fly', 'Push', 'Lift', 'Rush', 'Hop', 
    'Dash', 'Race', 'Spin', 'Pulse', 'Bolt', 'Edge', 'Gear', 'Wave', 
    'Zip', 'Flow', 'Trail', 'Wheel', 'Flex', 'Rover', 'Flux', 'Velo', 
    'Speed', 'Glide', 'Torque', 'Flash', 'Drift', 'Cruise', 'Boost', 'Volt', 
    'Shift', 'Zap', 'Vibe', 'Jolt', 'Bump', 'Haste', 
    '29', '34', '24', '19', '35', '21', '33', '31', '22', '12', '42', '50', 
    '23', '41', '20', '17', '18', '16', '27', '26', '30', '15', '14', '45', 
    '28', '40', '44'
  ];

  // Função para verificar se a combinação possui no máximo um número
  const isValidSuggestion = (word1, word2) => {
    // Verifica se tanto word1 quanto word2 são números
    const isWord1Number = !isNaN(word1);
    const isWord2Number = !isNaN(word2);

    // Retorna verdadeiro se pelo menos uma das palavras não for número
    return !(isWord1Number && isWord2Number);
  };

  // Função para gerar sugestões
  const generateSuggestions = () => {
    const uniqueSuggestions = new Set();

    while (uniqueSuggestions.size < 6) {
      let word1, word2;

      // Garante que word1 e word2 sejam diferentes
      do {
        word1 = words[Math.floor(Math.random() * words.length)];
        word2 = words[Math.floor(Math.random() * words.length)];
      } while (word1 === word2);

      // Valida a sugestão para ter no máximo um número
      if (isValidSuggestion(word1, word2)) {
        const suggestion = word1 + word2;
        uniqueSuggestions.add(suggestion);
      }
    }

    // Converte o Set para array e atualiza o estado
    setSuggestions(Array.from(uniqueSuggestions));
  };

  // Chama a função automaticamente ao carregar a página
  useEffect(() => {
    generateSuggestions();
  }, []);

  useApiEffect(getStates, (response) =>
    setStates(
      response.data.map((item) => ({ name: item.sigla, value: item.sigla }))
    )
  )

  useEffect(() => {
    request(
      () => getCitiesByState(UF),
      (response) =>
        setCities(
          response.data.map((item) => ({ name: item.nome, value: item.nome }))
        )
    )
  }, [UF])

  useEffect(() => {
    if (name) {
      setValue('domain', name.toLowerCase().replace(/[^a-z0-9]/g, ''))
    }
  }, [name])

  const onSubmit = (data, e) => {
    e.preventDefault()
    const { logo } = data

    delete data.logo

    setRegister({ ...(save || {}), app: data })

    data.tax = 0.05

    data.location = {
      state: data.state,
      city: data.city,
    }

    data.colors = {
      primary: colory.hex.toUpperCase(),
    }

    delete data.state
    delete data.city
    delete data.color

    request(
      () => verifyAppData(data),
      () =>
        navigate('/app/cadastro', {
          state: { ...state, app: { ...data, logo } },
        }),
      formErrors(setError)
    )
  }

  // Função para atualizar o campo de nome com a sugestão selecionada
  const handleSuggestionClick = (suggestion) => {
    setValue('name', suggestion)
  }

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <ToolBar>
        <img
          className="logo"
          src={appLogo}
          alt="logo"
          style={{ height: '74px' }}
        />
      </ToolBar>
      <Container primary={color}>
        <div className="left">
          {Object.entries(fields).map(([key, value]) => (
            <div className="input-wrapper" key={key}>
              <h1 style={{ font: '300 30px Comfortaa,sans-serif' }}>
                Crie seu app grátis!
              </h1>

              {/*<p style={{ font: '300 16px Comfortaa,sans-serif' }}>
                Crie seu app de mobilidade em 2 minutos.
              </p>/*/}

              <SuggestionsContainer>
                <p>Sugestões de nome(poderá alterar depois)</p>
              <SuggestionsList>
                {suggestions.map((suggestion, index) => (
                  <SuggestionCircle key={index} onClick={() => handleSuggestionClick(suggestion)}>
                    {suggestion}
                  </SuggestionCircle>
                ))}
              <GenerateButton type="button" onClick={generateSuggestions}>
                +
              </GenerateButton>
            </SuggestionsList>
          </SuggestionsContainer>

              {/*<p>{value.label}</p>
              <NInput
                error={formState.errors[key]?.message}
                {...value}
                {...register(key)}
              />*/}
            </div>
          ))}
 
          <div className="input-color">
            <p>Mudar cor:</p>
            <InputColor
              initialValue="#944bbb"
              placement="right"
              onChange={setColory}
            />
          </div>
          <Controller
            control={control}
            name="state"
            render={({ field, fieldState }) => (
              <div className="input-wrapper">
                <p>Estado:</p>
                <NSelector
                  options={states}
                  {...field}
                  value={field.value}
                  onChange={(option) => {
                    setValue('city', '')

                    field.onChange(option.name)
                  }}
                  error={fieldState.error?.message}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="city"
            render={({ field, fieldState }) => (
              <div className="input-wrapper">
                <p>Cidade:</p>
                <NSelector
                  options={cities}
                  {...field}
                  value={field.value}
                  onChange={(option) => field.onChange(option.name)}
                  error={fieldState.error?.message}
                />
              </div>
            )}
          />
          <NButton
            style={{ margin: '24px 0' }}
            label={'Criar meu app grátis'}
            color="green"
            /* onClick={(el) => { el.preventDefault(); setModal(true)}} */ type="submit"
          />

          <a
            href="https://www.weptek.com.br/"
            target="_blank"
            style={{
              font: '300 16px Comfortaa,sans-serif',
              textDecoration: 'underline',
            }}
            rel="noreferrer"
          >
            Saber mais informações
          </a>
        </div>

        <div className="separator" />
        <div className="right">
          <p
            style={{ font: '300 18px Comfortaa,sans-serif', marginBottom: 20 }}
          >
            Veja como vai ficar incrível o seu app
          </p>
          <NCarousel
            showArrows={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
          >
            {printsList.map((Print, index) => (
              <div key={index}>
                <Print style={{ fill: colory.hex, height: '500px' }} />
              </div>
            ))}
          </NCarousel>
        </div>
      </Container>
    </Wrapper>
  )
}

export default AppForm