import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { NButton, NInput, NCard } from '~/components'

import { Container } from './styles'
import { fields, schema } from './props'
import {
  disablePassenger,
  enablePassenger,
  getPassengersId,
  setPassengerForceForm,
} from '~/services/passengers'
import { useApi, useApiEffect } from '~/hooks'

const NCardPassenger = ({ id }) => {
  const [passenger, setPassenger] = useState({})

  const { request } = useApi()

  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  })

  useApiEffect(
    () => getPassengersId(id),
    (response) => {
      setPassenger(response.data)
    }
  )

  const attPassenger = () => {
    request(
      () => getPassengersId(id),
      (response) => {
        setPassenger(response.data)
      }
    )
  }

  const [edit, setEdit] = useState(false)
  const [modal, setModal] = useState(false)

  function placeholder(type) {
    if (passenger) {
      if (type === 'Pix:') return passenger.pix
      if (type === 'Nome:') return passenger.name
      if (type === 'Email:') return passenger.email
      if (type === 'Telefone:') return passenger.phone
    }
  }

  const onCancel = () => {
    setModal(false)
  }

  const changeStatus = () => {
    const service = passenger.active ? disablePassenger : enablePassenger
    request(
      () => service(id),
      () => attPassenger(),
      () => alert('error')
    )
    setModal(false)
  }

  const submit = (response) => {
    const data = response
    if (data.phone) data.phone = data.phone.replace(/\D/g, '')

    request(
      () => setPassengerForceForm(id, data),
      () => attPassenger(),
      () => alert('error')
    )

    setEdit(false)
  }

  return (
    <NCard
      title={passenger.name}
      modal={modal}
      onCancel={() => onCancel()}
      onConfirm={() => changeStatus()}
      content={
        <Container onSubmit={handleSubmit((data) => submit(data))}>
          {Object.entries(fields).map(([key, value]) => (
            <div key={key}>
              <p>{value.label}</p>
              <NInput
                disabled={!edit}
                placeholder={placeholder(value.label)}
                error={formState.errors[key]?.message}
                {...value}
                {...register(key)}
              />
            </div>
          ))}

          <div className="btns print-hide">
            {!edit && (
              <NButton
                type="button"
                color="gray"
                label="Editar"
                onClick={() => setEdit(true)}
              />
            )}
            {edit && <NButton type="submit" color="green" label="Salvar" />}

            {passenger.active && (
              <NButton
                type="button"
                color="red"
                label="Desativar"
                onClick={() => setModal(true)}
              />
            )}
            {!passenger.active && (
              <NButton
                type="button"
                color="green"
                label="Ativar"
                onClick={() => setModal(true)}
              />
            )}
          </div>
        </Container>
      }
    />
  )
}

export default NCardPassenger
