import React, { useEffect, useState } from 'react'

import useOutsideClick from '~/hooks/useOutsideClick.js'
import { NModalSure } from '~/components'

import { Container, Top, Content } from './styles.js'

import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai'

const NCard = ({
  title,
  titleSize,
  content,
  modal,
  onCancel,
  onConfirm,
  onFocus,
}) => {
  const [open, setOpen] = useState(false)

  const cardClose = useOutsideClick(() => setOpen(false))

  useEffect(() => {
    if (open && typeof onFocus === 'function') {
      onFocus()
    }
  }, [open])

  return (
    <Container title={title} titleSize={titleSize} ref={cardClose} open={open}>
      <NModalSure
        visible={modal}
        label="Você tem certeza?"
        onCancel={() => onCancel()}
        confirmLabel="Sim, confimar"
        cancelLabel="Não, cancelar"
        onConfirm={() => onConfirm()}
        onClose={() => onCancel()}
      />
      <Top>
        {!open && (
          <AiOutlinePlusCircle
            onClick={() => setOpen(!open)}
            size="20px"
            className="icon print-hide"
          />
        )}
        {open && (
          <AiOutlineMinusCircle
            onClick={() => setOpen(!open)}
            size="20px"
            className="icon print-hide"
          />
        )}
        <p>{title}</p>
      </Top>
      <Content>{content}</Content>
    </Container>
  )
}

export default NCard
