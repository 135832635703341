import React, { useRef, useState } from 'react'

import {
  PageBase,
  NCardRoutes,
  NSearch,
  NButton,
  NModalAddRoute,
  Pagination,
} from '~/components'
import { useApi, useApiEffect } from '~/hooks'
import { createRoute, getRoutes, getRoutesNoLimit } from '~/services/routes'
import { Container } from './styles'

const Drivers = () => {
  const [routesList, setRoutesList] = useState([])
  const [modal, setModal] = useState(false)
  const [search, setSearch] = useState('')

  const pagination = useRef()

  const { request } = useApi()

  useApiEffect(getRoutesNoLimit, (response) =>
    setRoutesList(response.data.content)
  )

  const onSave = (data) => {
    request(
      () => createRoute(data),
      () => pagination.refresh(),
      (response) => console.log(response.data)
    )

    setModal(false)
  }

  const routesListCards = routesList.map((route, i) => {
    const regex = new RegExp(search, 'gi')

    return (
      route.boardingDistrict.name.match(regex) && (
        <NCardRoutes key={route.id} id={route.id} />
      )
    )
  })

  return (
    <PageBase
      title="Configurações => Rotas"
      content={
        <Container>
          <div className="div_top">
            <NSearch
              value={search}
              placeholder="Pesquise pelo embarque..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <NButton
              color="green"
              label="Adicionar Rota"
              onClick={() => setModal(true)}
            />
          </div>
          <NModalAddRoute
            visible={modal}
            onClose={() => setModal(false)}
            label="Adicione uma rota"
            save={(data) => {
              onSave(data)
            }}
          />

          {!search ? (
            <Pagination
              service={getRoutes}
              render={(route, i) => (
                <NCardRoutes key={route.id} id={route.id} />
              )}
            />
          ) : (
            routesListCards
          )}
        </Container>
      }
    ></PageBase>
  )
}

export default Drivers
