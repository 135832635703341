import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  border-radius: 20px;
  background-color: white;
  padding: 20px 22px;
  height: 200px;
  justify-content: space-between;
`
export const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden; /* Adiciona um overflow escondido para que o texto quebre dentro do container */

  /* TODO: COLOCAR LIMITE NA LARGURA DO TEXTO */
`

export const MidArea = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  width: 100%;
  word-break: break-all;
  overflow: hidden; /* Adiciona um overflow escondido para que o texto quebre dentro do container */
`

export const BotArea = styled.div`
  display: flex;
  align-items: center;
`

export const TextTopCustom = styled.span`
  width: 70%;
  font-size: 18px; /* Ajuste o tamanho da fonte conforme necessário */
  max-width: 100%; /* Define a largura máxima para que o texto não ultrapasse o container */
  white-space: nowrap; /* Define o comportamento de quebra de linha normal */
  overflow: hidden; /* Esconde qualquer parte do texto que exceda a largura máxima */
  text-overflow: ellipsis; /* Adiciona reticências (...) para indicar que o texto foi cortado (opcional) */
`

export const TextMidCustom = styled.p`
  font-size: 14px; /* Ajuste o tamanho da fonte conforme necessário */
  overflow: auto; /* Esconde qualquer parte do texto que exceda a largura máxima */
  text-overflow: ellipsis;
  width: 100%;
  max-height: 80px;
`

export const PriceCustom = styled.span`
  font-size: 14px; /* Ajuste o tamanho da fonte conforme necessário */
  max-width: 100%; /* Define a largura máxima para que o texto não ultrapasse o container */
  white-space: nowrap; /* Define o comportamento de quebra de linha normal */
  overflow: hidden; /* Esconde qualquer parte do texto que exceda a largura máxima */
  text-overflow: ellipsis; /* Adiciona reticências (...) para indicar que o texto foi cortado (opcional) */
`
