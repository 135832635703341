import React, { forwardRef } from 'react';
import { InputStyled } from './styles';

const InputEditable = ({ color, backgroundColor, width, value = null, placeholder, txtAlign, ...props }, ref) => {
  return <InputStyled ref={ref} txtAlign={txtAlign} placeholder={placeholder} value={value} color={color} backgroundColor={backgroundColor} width={width} {...props} />;
};

const NInputEditable = forwardRef(InputEditable);

export default NInputEditable;
