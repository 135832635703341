import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
  align-items: center;
  justify-content: center;

  .div_next {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;
    gap: 15px;

    .advanced {
      font-size: 30px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.secondary || '#5A5A5A'};
    }
  }
`

export const ContainerLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  transition: color 0.3s ease;

  a {
    font-size: 20px;
    color: inherit;
  }

  .icon_link {
    cursor: pointer;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary || '#007ACC'};
  }

  @media only screen and (min-width: 800px) {
    top: 40px;

    a {
      font-size: 25px;
    }
  }
`

export const ContainerComments = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  padding: 10px;

  div {
    margin-right: 24px;
  }

  div:last-child {
    margin-right: 0;
  }

  /* Barra de rolagem personalizada */
  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`

export const ContainerCards = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  padding: 10px;

  div {
    margin-right: 24px;
  }

  div:last-child {
    margin-right: 0;
  }

  /* Barra de rolagem personalizada */
  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`

export const ContainerTax = styled.div`
  display: flex;
  width: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

  .border {
    border-left: 1px solid #d2d2d2;
  }

  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 10px;
    width: 33%;

    p,
    input {
      font-size: 15px;
      color: #333;
    }

    input {
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      padding: 5px;
      width: 40px;
      text-align: center;
    }

    .icon {
      margin-left: 10px;
      color: ${({ theme }) => theme.colors.primary};
    }

    @media only screen and (max-width: 700px) {
      flex-direction: column;

      p,
      input {
        font-size: 12px;
      }
    }
  }
`
