import styled from 'styled-components'

export const Container = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 100%;

  justify-content: space-between;
  flex-direction: row;
  display: flex;

  @media (max-width: 916px) {
    /* Estilo para tablets */
    flex-direction: column;
    display: flex;
    align-items: center;
    height: 360px;
  }
`

// export const ContainerButton = styled.div`
//   display: flex;
//   align-items: center;

//   @media (max-width: 916px) {
//     margin-top: 18px;
//   }
// `
