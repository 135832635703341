import React, { useEffect, useState } from 'react'
import { NButton, PageBase } from '~/components'
import {
  Container,
  ContainerButtonsLarge,
  CheckboxContainer,
  CheckboxOption,
  CheckboxInput,
  ContainerButtonsSmall,
  ContainerTax,
  Title,
} from './styles'
import NInputEditable from '~/components/NInputEditable'
import { updateLicensedData, getLicensedData } from '~/services/licenseds'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

const Charges = () => {
  const [submitBtn, setSubmitBtn] = useState(false)
  const [hasPremium, setHasPremium] = useState(false) // Estado para verificar se o usuário é premium
  const { handleSubmit, register, setValue } = useForm({})
  const navigate = useNavigate()

  const urlSite = 'https://asaas.com/'

  // Função para determinar a cor do botão de edição
  function returnColorBtn(state) {
    return state === true ? 'green' : null
  }

  // Função para lidar com a edição das cobranças
  const handleEditCharges = handleSubmit(async (data) => {
    if (!hasPremium) {
      alert("Pagamentos automáticos não habilitado. Habilite agora mesmo para poder editar essas configurações.")
      return
    }

    const formattedData = {
      ...data,
      typeBilling: Number(data.typeBilling),
      minimumValue: Number(data.minimumValue),
    }
    await updateLicensedData(formattedData)
    setSubmitBtn(!submitBtn)
  })

  useEffect(() => {
    getLicensedData().then(({ data }) => {
      setValue('minimumValue', data?.minimumValue);
      setValue('typeBilling', data?.typeBilling);
      setValue('walletId', data?.walletId);
      setHasPremium(data?.app?.accountPremium); // Define o estado premium corretamente
    });
}, [setValue]);


  return (
    <PageBase
      title="Cobranças"
      content={
        <Container onSubmit={handleEditCharges}>
          <ContainerTax>
            <ContainerButtonsSmall>
              <p className="textNormal">Frequência das cobranças:</p>
              <CheckboxContainer>
                <CheckboxOption>
                  <CheckboxInput
                    type="radio"
                    value="1"
                    {...register('typeBilling')}
                    disabled={!hasPremium} // Desabilita o campo se não for premium
                  />
                  Diariamente
                </CheckboxOption>
                <CheckboxOption>
                  <CheckboxInput
                    type="radio"
                    value="7"
                    {...register('typeBilling')}
                    disabled={!hasPremium} // Desabilita o campo se não for premium
                  />
                  Semanalmente
                </CheckboxOption>
                <CheckboxOption>
                  <CheckboxInput
                    type="radio"
                    value="31"
                    {...register('typeBilling')}
                    disabled={!hasPremium} // Desabilita o campo se não for premium
                  />
                  Mensalmente
                </CheckboxOption>
              </CheckboxContainer>
            </ContainerButtonsSmall>
          </ContainerTax>

          <ContainerTax style={{ justifyContent: 'start' }}>
            <ContainerButtonsSmall>
              <p className="textNormal">Valor mínimo para cobrança:</p>
              <NInputEditable
                width="20%"
                placeholder="R$20,00"
                {...register('minimumValue')}
                disabled={!hasPremium} // Desabilita o campo se não for premium
              />
            </ContainerButtonsSmall>
          </ContainerTax>

          <p className="description">
            Para receber pagamentos automáticos dos seus motoristas e entregadores, crie uma conta no&nbsp;
            <a
              target="_blank"
              href={urlSite}
              className="description_link"
              rel="noreferrer"
            >
              {'https://www.asaas.com/r/32bcb81c-c81d-45a1-90ce-af76870b0b0b'}
            </a>
            &nbsp;e insira seu Wallet ID para gerenciar seu dinheiro recebido no ASAAS.
          </p>
          <p className="description">Taxas por cada fatura paga: R$1.99/pix</p>
          <ContainerTax style={{ justifyContent: 'start' }}>
            <ContainerButtonsLarge>
              <p className="textNormal">Insira seu Wallet ID Asaas:</p>
              <NInputEditable
                txtAlign="start"
                width="50%"
                placeholder="Wallet ID aqui"
                {...register('walletId')}
                disabled={!hasPremium} // Desabilita o campo se não for premium
              />
            </ContainerButtonsLarge>
          </ContainerTax>
          <div className="center">
            <NButton
              type="submit"
              onClick={handleEditCharges}
              className="ButtonEdit"
              label="Editar"
              color={returnColorBtn(submitBtn)}
            />
          </div>

          {!hasPremium && (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <p>Pagamentos automáticos não habilitado. Habilite agora mesmo para poder editar essas configurações.</p>
              <button
                onClick={() => navigate('/premium')}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginTop: '10px'
                }}
              >
                Saiba Mais
              </button>
            </div>
          )}

          <Title>
            <p className="title">Como fazer?</p>
          </Title>

          <p className="description">
            1 - Crie sua conta no site&nbsp;
            <a
              target="_blank"
              href={urlSite}
              className="description_link"
              rel="noreferrer"
            >
              {'https://www.asaas.com/r/32bcb81c-c81d-45a1-90ce-af76870b0b0b'}
            </a>
            &nbsp;
          </p>
          <p className="description">2 - Com sua conta criada, faça login para entrar na plataforma</p>
          <p className="description">3 - Clique em PERFIL</p>
          <p className="description">4 - Clique em INTEGRAÇÕES</p>
          <p className="description">5 - Agora copie o seu Wallet ID e cole ele aqui nesta página.</p>
        </Container>
      }
    ></PageBase>
  )
}

export default Charges
