import React from 'react'
import { Container, Top } from './styles'

const NCardInfo = ({
  title = 'Viagens finalizadas',
  icon,
  info = '0',
  bgColor = '#ffffff', // cor padrão para evitar problemas
}) => (
  <Container bgColor={bgColor}>
    <Top>
      <div className="icon">{icon}</div>
      <p className="title">{title}</p>
    </Top>
    <div className="border" />
    <p className="info">{info}</p>
  </Container>
)

export default NCardInfo

