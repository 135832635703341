import styled from 'styled-components'

export const Container = styled.div`
  background: ${({ bgColor }) => bgColor || '#ffffff'}; /* cor padrão */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  max-width: 300px;
  padding: 15px 55px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  .info {
    font-size: 25px;
    padding: 25px 0;
    color: #ffffff;
    font-weight: bold;
  }

  .border {
    width: 80%;
    border-bottom: 2px solid #ffffff;
  }
`

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  > .title {
    font-size: 15px;
    color: #ffffff;
    font-weight: bold;
  }

  > .icon {
    color: #ffffff;
    margin-right: 10px;
    font-size: 24px;
  }
`
