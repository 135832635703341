import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.background};

  .premium-container {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    padding: 32px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 12px;

    .premium-image {
      width: 60px;
      height: 60px;
    }

    h2 {
      font-size: 24px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .separator {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};
    width: 80%;
    margin: 20px 0;
  }

  .description {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.text};
    line-height: 1.6;
    max-width: 600px;
  }

  .benefits {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.secondaryText};
    line-height: 1.6;
    max-width: 600px;

    p {
      margin-bottom: 8px;
      strong {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  .price-section {
    text-align: center;
    .price-title {
      font-size: 20px;
      font-weight: bold;
    }

    .price-container {
      background-color: ${({ theme }) => theme.colors.highlight};
      padding: 16px;
      border-radius: 8px;
      margin: 16px 0;

      .price {
        font-size: 36px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    .discount-note {
      font-size: 16px;
      color: ${({ theme }) => theme.colors.danger};
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    .pay-now-button {
      height: 50px;
      width: 200px;
      background-color: ${({ theme }) => theme.colors.primary};
      color: #fff;
      font-size: 18px;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: ${({ theme }) => theme.colors.primaryDark};
      }
    }
  }
`;