import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { NButton, NCard, NInput, NPicture, NSelector } from '~/components'

import { useApi, useApiEffect } from '~/hooks'
import {
  disableDriver,
  enableDriver,
  getDriversId,
  setDriverForceForm,
} from '~/services/drivers'
import { fields, schema } from './props'
import { Container, DriverName, DriverStatus } from './styles'

const CarType = {
  PRIVATE_CAR: 'private_car',
  PREMIUM_CAR: 'premium_car',
  TAXI: 'taxi',
  MOTORBIKE_TAXI: 'motorbike_taxi',
  CAR_DELIVERY_MAN: 'car_delivery_man',
  MOTORBIKE_DELIVERY_NAN: 'motorbike_delivery_man',
}

const carTypeOptions = [
  {
    name: 'Carro particular',
    value: 'private_car',
  },
  {
    name: 'Carro Premium',
    value: 'premium_car',
  },
  {
    name: 'Táxi',
    value: 'taxi',
  },
  {
    name: 'Mototáxi',
    value: 'motorbike_taxi',
  },
  {
    name: 'Entregador - carro',
    value: 'car_delivery_man',
  },
  {
    name: 'Entregador - moto',
    value: 'motorbike_delivery_man',
  },
]

const NCardDriver = ({ id }) => {
  const [driver, setDriver] = useState({})

  const { request } = useApi()

  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  })

  useApiEffect(
    () => getDriversId(id),
    (response) => {
      setDriver(response.data)
    }
  )

  const attDriver = () => {
    request(
      () => getDriversId(id),
      (response) => {
        setDriver(response.data)
      }
    )
  }

  const [edit, setEdit] = useState(false)
  const [modal, setModal] = useState(false)

  function placeholder(type) {
    if (driver) {
      if (driver.car) {
        if (type === 'Cor do veículo:') return driver.car.color
        if (type === 'Ano do veículo:') return driver.car.year
        if (type === 'Modelo do veículo:') return driver.car.model
        if (type === 'Marca do veículo:') return driver.car.brand
        if (driver.car.licensePlate) {
          if (type === 'Placa do veículo:') return driver.car.licensePlate.value
        }
        if (type === 'Tipo do veiículo:') return driver.car.type
      }
      if (type === 'Pix:') return driver.pix
      if (type === 'Nome:') return driver.name
      if (type === 'Email:') return driver.email
      if (type === 'Telefone:') return driver.phone
    }
  }

  const onCancel = () => {
    setModal(false)
  }

  const changeStatus = () => {
    const service = driver.active ? disableDriver : enableDriver
    request(
      () => service(id),
      () => attDriver(),
      () => alert('error')
    )
    setModal(false)
  }

  const submit = (response) => {
    const data = response
    if (data.phone) data.phone = data.phone.replace(/\D/g, '')
    if (data.cpf) data.cpf = data.cpf.replace(/\D/g, '')

    const formData = new FormData()

    Object.entries(data).forEach(([key, value]) => {
      if (value) formData.append(key, value)
    })

    request(
      () => setDriverForceForm(id, formData),
      () => attDriver(),
      () => alert('error')
    )

    setEdit(false)
  }

  return (
    <NCard
      title={
        <DriverName>
          {driver.name} <DriverStatus active={driver.active} />
        </DriverName>
      }
      modal={modal}
      onCancel={() => onCancel()}
      onConfirm={() => changeStatus()}
      content={
        <Container onSubmit={handleSubmit((data) => submit(data))}>
          {Object.entries(fields).map(([key, value]) => (
            <div key={key}>
              <p>{value.label}</p>
              <NInput
                disabled={!edit}
                placeholder={placeholder(value.label)}
                error={formState.errors[key]?.message}
                {...value}
                {...register(key)}
              />
            </div>
          ))}

          {driver.car && (
            <Controller
              control={control}
              name="typeCar"
              render={({ field, fieldState }) => (
                <div>
                  <p className="label">Selecione o tipo do veículo:</p>
                  <NSelector
                    options={carTypeOptions}
                    {...field}
                    value={field.value ?? driver.car.type}
                    placeholder={driver.car.type}
                    disabled={!edit}
                    onChange={(option) => field.onChange(option.value)}
                    error={fieldState.error?.message}
                  />
                </div>
              )}
              rules={{ required: true }}
            />
          )}

          <p className="label">Insira a foto da placa do veículo:</p>
          {driver.car && (
            <Controller
              control={control}
              name="licensePlateImage"
              render={({ field }) => (
                <NPicture
                  id="placa"
                  square
                  {...field}
                  disabled={!edit}
                  imgDefault={driver.car.licensePlate.url}
                />
              )}
              rules={{ required: true }}
            />
          )}

          <p className="label">Insira a foto da sua cnh:</p>
          {driver.car && (
            <Controller
              control={control}
              name="cnhImage"
              render={({ field }) => (
                <NPicture
                  id="cnh"
                  square
                  {...field}
                  disabled={!edit}
                  imgDefault={driver.docImages.cnh}
                />
              )}
              rules={{ required: true }}
            />
          )}

          <p className="label">Insira a foto da sua identidade:</p>
          {driver.docImages && (
            <Controller
              control={control}
              name="identityImage"
              render={({ field }) => (
                <NPicture
                  id="identidade"
                  square
                  {...field}
                  disabled={!edit}
                  imgDefault={driver.docImages.identity}
                />
              )}
              rules={{ required: true }}
            />
          )}

          <p className="label">Insira a foto da sua crv:</p>
          {driver.docImages && (
            <Controller
              control={control}
              name="crvImage"
              render={({ field }) => (
                <NPicture
                  id="crv"
                  square
                  {...field}
                  disabled={!edit}
                  imgDefault={driver.docImages.crv}
                />
              )}
              rules={{ required: true }}
            />
          )}

          <p className="label">Insira a foto da sua crlv:</p>
          {driver.docImages && (
            <Controller
              control={control}
              name="crlvImage"
              render={({ field }) => (
                <NPicture
                  id="crlv"
                  square
                  {...field}
                  disabled={!edit}
                  imgDefault={driver.docImages.crlv}
                />
              )}
              rules={{ required: true }}
            />
          )}

          <div className="btns">
            {!edit && (
              <NButton
                type="button"
                color="gray"
                label="Editar"
                onClick={() => setEdit(true)}
                className="print-hide"
              />
            )}
            {edit && (
              <NButton
                type="submit"
                color="green"
                label="Salvar"
                className="print-hide"
              />
            )}

            {driver.active && (
              <NButton
                type="button"
                color="red"
                label="Desativar"
                onClick={() => setModal(true)}
                className="print-hide"
              />
            )}
            {!driver.active && (
              <NButton
                type="button"
                color="green"
                label="Ativar"
                onClick={() => setModal(true)}
                className="print-hide"
              />
            )}
          </div>
        </Container>
      }
    />
  )
}

export default NCardDriver
