import styled from 'styled-components'

import NModal from '../NModal'

export const Container = styled(NModal)`
  .body {
    padding: 50px 0 50px;
    gap: 15px;

    > .label {
      margin-bottom: 24px;

      max-width: 90%;

      text-align: center;
      font-size: 25px;
    }

    .confirm-button {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .title_w {
      width: 100%;
      margin-left: 30px;
      margin-bottom: 5px;
      text-align: start !important;
    }

    > .icon_change {
      color: ${({ theme }) => theme.colors.primary};
      margin-top: 10px;
    }
  }
`

export const Wrapper = styled.div`
  ${({ theme }) => theme.styles.itemWidth}
  height: 50px;
  border-radius: 15px;
  background: ${({ theme }) => theme.colors.dark};

  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    font-size: 20px;
    color: white;
  }
`

export const ContainerTest = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ visible }) => (visible ? 100 : -30)};
  overflow-y: hidden;

  padding: 0 24px;

  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);

  opacity: ${({ visible }) => (visible ? 1 : 0)};

  display: flex;
  align-items: center;
  justify-content: center;

  .body {
    overflow: hidden;

    width: 90%;
    max-height: 95%;
    max-width: 544px;
    border-radius: 20px;
    background: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    ${({ visible }) => !visible && 'transform: translateY(100%);'}
  }
`
