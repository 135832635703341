import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  NewContainer,
  ContentItem,
  Top,
  LabelItem,
  Scroll,
  ContentDriversCards,
  ContainerName,
  ContainerNotFoundDatas,
} from './styles';
import { FaAddressCard } from 'react-icons/fa';
import { BiCheck, BiDollar, BiDollarCircle, BiUser } from 'react-icons/bi';
import { BsCheck2 } from 'react-icons/bs';
import { ImCancelCircle } from 'react-icons/im';
import { useApi } from '~/hooks';
import { computePayment } from '~/services/apps';
import { MdSearchOff } from 'react-icons/md';
import { updateLicensedData, getLicensedData } from '~/services/licenseds';

const NTableCard = ({ drivers = [] }) => {
  const { request } = useApi();
  const navigate = useNavigate();
  const [hasPremium, setHasPremium] = useState(false); // Controle de pagamento automático

  const payed = (id, name, isPaymentEnabled) => {
    if (hasPremium) {
      alert(`Recebimento automático habilitado para o motorista ${name}.`);
      return;
    }

    if (window.confirm(`Confirmar recebimento do motorista ${name}?`)) {
      request(
        () => computePayment(id),
        () => alert(`Registrado que o motorista ${name} pagou ao app`)
      );
    }
  };

  useEffect(() => {
    getLicensedData().then(({ data }) => {
      setHasPremium(data?.app?.accountPremium); // Define o estado premium corretamente
    });
}, []);


  const driversCards = drivers?.map((driver, index) => (
    <Scroll key={index}>
      <ContentItem>
        <ContainerName>
          <BiUser className="icon" />
          <p className="label_truncate">{driver.driver.name}</p>
        </ContainerName>

        <p className="item">Faturamento: {driver.gains.total}</p>
        <p className="item">A receber: {driver.gains.payment}</p>
        <p className="item">{driver.paid ? 'pago' : 'não pago'}</p>

        {!driver.paid && (
          <BiCheck
            size={22}
            className="icon"
            onClick={() => payed(driver.id, driver.driver.name, driver.isPaymentEnabled)}
          />
        )}
      </ContentItem>
    </Scroll>
  ));

  return (
    <NewContainer>
      {/*<Top>
        <div className="div_name">
          <FaAddressCard className="icon" />
          <p>Motoristas</p>
        </div>
        <div className="div_name">
          <BiDollarCircle className="icon" />
          <p>Faturamento total</p>
        </div>
        <div className="div_name">
          <ImCancelCircle className="icon" />
          <p>A receber</p>
        </div>
        <div className="div_name">
          <BsCheck2 className="icon" />
          <p>Status</p>
        </div>
        <div className="div_name">
          <BsCheck2 className="icon" />
          <p>Pago?</p>
        </div>
      </Top>*/}

      {!hasPremium && (
        <div style={{ margin: '20px 0', textAlign: 'center' }}>
          <span>Sua cobrança está manual! Quer habilitar a cobrança automática dos motoristas?</span>
          <button
            onClick={() => navigate('/premium')}
            style={{
              padding: '8px 12px',
              marginLeft: '10px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Saiba mais
          </button>
          <div className="separator"></div>
        </div>
      )}

      {drivers?.[0] ? (
        <ContentDriversCards>{driversCards}</ContentDriversCards>
      ) : (
        <ContainerNotFoundDatas>
          <p>Nenhum dado encontrado</p>
          <MdSearchOff color="#4D4D4D" size={42} />
        </ContainerNotFoundDatas>
      )}
    </NewContainer>
  );
};

export default NTableCard;

