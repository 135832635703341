import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  height: 100%;

  .div_next {
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.colors.primary};

    margin-bottom: 20px;

    gap: 15px;

    .advanced {
      font-size: 30px;
    }
  }
`
