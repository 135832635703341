import styled from 'styled-components'

export const Container = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .description {
    font-size: 18px;
    font-weight: 700;
  }

  .description_link {
    font-size: 18px;
    font-weight: 700;
    color: blue;
  }

  .div_next {
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.colors.primary};

    margin-bottom: 20px;

    gap: 15px;

    .advanced {
      font-size: 30px;
    }
  }

  .div_top {
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 16px;

    @media only screen and (min-width: 900px) {
      padding: 0 15% 0 15%;
      flex-direction: row;
      button {
        width: 200px;
      }
    }
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .ButtonEdit {
    border-radius: 8px !important;
    /* position: fixed !important; */
    /* bottom: 20px !important; */
    right: 20px !important;
  }

  > a {
    align-self: center;
  }
`

export const Title = styled.div`
  .title {
    font-size: 32px;
    line-height: 28px;
  }

  margin-top: 12px;
  width: 90%;
`

export const ContainerButtonsSmall = styled.div`
  padding: 12px 18px;
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  .textNormal {
    font-size: 18px;
    font-weight: 700;
    min-width: 100px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .textNormal {
      font-size: 14px;
      font-weight: 700;
    }
    input {
      min-width: 100px;
    }

    button {
      max-width: 180px;
      word-break: break-word;
      min-width: 100px;
    }
  }
`

export const ContainerButtonsLarge = styled.div`
  padding: 12px 18px;
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  .textNormal {
    font-size: 18px;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .textNormal {
      font-size: 14px;
      font-weight: 700;
    }

    input {
      width: 96%;
    }

    button {
      max-width: 180px;
      word-break: break-word;
      min-width: 100px;
    }
  }
`

export const ContainerTax = styled.div`
  display: flex;
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 10px 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const CheckboxContainer = styled.div`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
  width: 70%;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 260px;
  }
`

export const CheckboxOption = styled.label`
  display: flex;
  align-items: center;
  /* margin-right: 10px; */
  word-break: break-word;
  font-size: 16px;
`

export const CheckboxInput = styled.input`
  margin-right: 5px;
  width: 18px;
  height: 18px;
  margin: 4px 4px;
`
