import React from 'react'

import { NCard, NInput } from '~/components'

import { Container, Passenger } from './styles'

const NCardTrip = ({
  driver,
  embarkation,
  disembarkation,
  passengers,
  ...rest
}) => {
  return (
    <NCard
      {...rest}
      title={`${driver || 'Indefinido'} - ${embarkation} - ${disembarkation}`}
      content={
        <Container>
          <div className="left">
            <div>
              <p>Embarque:</p>
              <NInput disabled={true} value={embarkation} />
            </div>
            <div>
              <p>Desembarque:</p>
              <NInput disabled={true} value={embarkation} />
            </div>
            <div>
              <p>Motorista:</p>
              <NInput disabled={true} value={driver} />
            </div>
          </div>
          <div className="right">
            {passengers &&
              passengers.map((item) => (
                <Passenger status={item.status}>
                  <div className="circle" />
                  <p>
                    {item.passenger.name} - {item.cost} -{' '}
                    {item.boardingAddress.name.split(',')[0]} -{' '}
                    {item.landingAddress.name.split(',')[0]}
                  </p>
                </Passenger>
              ))}
          </div>
        </Container>
      }
    />
  )
}

export default NCardTrip
