import React, { useState } from 'react'

import { PageBase, Pagination, NCardTrip } from '~/components'
import { useApi } from '~/hooks'
import { getTripById, getTripsByStatus } from '~/services/trips'
import { Container } from './styles'

const Trips = ({ status, type }) => {
  const [passengers, setPassengers] = useState({ id: null, list: [] })

  const { request } = useApi()

  const getTrip = (id) => {
    request(
      () => getTripById(id),
      (response) => {
        setPassengers({ id: id, list: response.data.passengers })
      }
    )
  }

  return (
    <PageBase
      title={`Viagens ${type}`}
      content={
        <Container>
          <Pagination
            service={(page) => getTripsByStatus(status, page)}
            render={(trip) => (
              <NCardTrip
                key={trip.id}
                embarkation={trip.route.boardingDistrict}
                disembarkation={trip.route.landingDistrict}
                driver={trip.driver?.name}
                onFocus={() => getTrip(trip.id)}
                passengers={trip.id === passengers.id && passengers.list}
              />
            )}
          />
          <button className="print-hide" onClick={window.print}>
            Gerar PDF
          </button>
        </Container>
      }
    />
  )
}

Trips.Running = () => <Trips status="running" type="em andamento" />

Trips.Finished = () => <Trips status="finished" type="finalizadas" />

Trips.Canceled = () => <Trips status="canceled" type="canceladas" />

export default Trips
