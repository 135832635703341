import { object, string, } from 'yup'


export const fields = {
  name: {
    label: 'Nome do app:',
    type: 'text',
  },
  // domain: {
  //   label: 'Nome do app (Minúsculo e sem espaço):',
  //   type: 'text',
  // },

  /*tax: {
    label: 'Taxa sobre as corridas:',
    type: 'text',
    mask: '99%',
  },*/

}

export const schema = object({
  name: string().required('Preencha esse campo!').min(4, 'Nome muito curto!'),
  domain: string()
    .required('Preencha esse campo!')
    .min(4, 'Domínio muito curto!')
    .matches(/^[a-z][a-z\-_\d]*$/, 'Formato inválido!'),
  state: string().required('Preencha esse campo!'),
  city: string().required('Preencha esse campo!'),
  /*tax: string()
    .required('Preencha esse campo!')
    .matches(/^[\d]{2}$/, 'Precisa ser um valor entre 0 e 100!'),*/
}).required()
