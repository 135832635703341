import { object, string } from 'yup'

export const fields = {
  name: {
    label: 'Nome:',
    type: 'text',
  },
  email: {
    label: 'Email:',
    type: 'email',
  },
  phone: {
    label: 'Telefone:',
    type: 'text',
    mask: '55 (99) 99999-9999',
  },
  // password: {
  //   label: 'Senha:',
  //   type: 'password',
  // },
}

export const schema = object({
  name: string(),
  email: string().email('Email inválido!'),
  phone: string(),
  // password: string()
  //
  //   .min(8, 'Senha muito curta!'),
})
