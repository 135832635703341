import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { ReactComponent as Preview } from '~/assets/preview.svg'
import { NButton, NInput, NPicture, NSelector, PageBase } from '~/components'
import { useApi, useApiEffect } from '~/hooks'
import { getAppData, updateApp } from '~/services/apps'
import { getStates, getCitiesByState } from '~/services/locations'

import { Container, Wrapper } from './styles'
import { fields, schema } from './props'

const ConfigApp = () => {
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [edit, setEdit] = useState(false)
  const [defaultLogo, setDefaultLogo] = useState(null)
  const { request } = useApi()
  const { control, formState, register, watch, handleSubmit, setValue, reset } =
    useForm({ resolver: yupResolver(schema), mode: 'all' })

  const name = watch('name')
  const domain = watch('domain')
  const color = watch('color')
  const UF = watch('state')
  const logo = watch('logo')

  useApiEffect(getAppData, (response) => {
    setDefaultLogo(response.data.logo)

    reset({
      name: response.data.name,
      domain: response.data.domain,
      state: response.data.location?.state,
      city: response.data.location?.city,
      color: response.data.colors?.primary || '#944BBB',
    })
  })

  useApiEffect(getStates, (response) =>
    setStates(
      response.data.map((item) => ({ name: item.sigla, value: item.sigla }))
    )
  )

  useEffect(() => {
    request(
      () => getCitiesByState(UF),
      (response) =>
        setCities(
          response.data.map((item) => ({ name: item.nome, value: item.nome }))
        )
    )
  }, [UF])

  const onSubmit = (data) => {
    if (!edit) {
      setEdit(true)

      return
    }

    const formData = new FormData()

    formData.append('name', data.name)
    formData.append('domain', data.domain)
    formData.append('colors[primary]', data.color)
    formData.append('location[state]', data.state)
    formData.append('location[city]', data.city)
    formData.append('logo', data.logo)

    console.log(data.logo)

    request(
      () => updateApp(formData),
      () => setEdit(false),
      () => alert('error')
    )
  }

  return (
    <PageBase
      title="Personalização"
      content={
        <Wrapper onSubmit={handleSubmit(onSubmit)}>
          <Container primary={color}>
            <div className="left">
              <Controller
                control={control}
                name="logo"
                render={({ field }) => (
                  <div className="input-wrapper">
                    <p>Logo (escolha uma imagem png de tamanho 192x192):</p>
                    <div className="logo">
                      <NPicture
                        id="logo"
                        {...field}
                        disabled={!edit}
                        accept="image/*"
                        imgDefault={defaultLogo}
                        restrictWidth={192}
                        restrictHeight={192}
                      />
                    </div>
                  </div>
                )}
                rules={{ required: true }}
              />
              {Object.entries(fields).map(([key, value]) => (
                <div className="input-wrapper">
                  <p>{value.label}</p>
                  <NInput
                    key={key}
                    error={formState.errors[key]?.message}
                    disabled={!edit}
                    {...value}
                    {...register(key)}
                  />
                </div>
              ))}
              <Controller
                control={control}
                name="state"
                render={({ field, fieldState }) => (
                  <div className="input-wrapper">
                    <p>Estado:</p>
                    <NSelector
                      options={states}
                      {...field}
                      disabled={!edit}
                      value={field.value}
                      onChange={(option) => {
                        setValue('city', '')

                        field.onChange(option.name)
                      }}
                      error={fieldState.error?.message}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="city"
                render={({ field, fieldState }) => (
                  <div className="input-wrapper">
                    <p>Cidade:</p>
                    <NSelector
                      options={cities}
                      {...field}
                      disabled={!edit}
                      value={field.value}
                      onChange={(option) => field.onChange(option.name)}
                      error={fieldState.error?.message}
                    />
                  </div>
                )}
              />
            </div>
            <div className="separator" />
            <div className="right">
              <p className="domain">weptek.app/{domain}</p>
              <div className="preview-container">
                <img
                  src={logo ? URL.createObjectURL(logo) : defaultLogo}
                  alt="logo"
                />
                <p className="name">{name}</p>
                <Preview className="preview" />
              </div>
            </div>
          </Container>
          <NButton
            label={edit ? 'Salvar' : 'Editar'}
            type="submit"
            color={edit ? 'green' : 'gray'}
          />
        </Wrapper>
      }
    />
  )
}

export default ConfigApp