import React, { useRef, useState } from 'react'
import {
  PageBase,
  Pagination,
  NSearch,
  NButton,
  NCardDistrict,
  NModalAddDistrict,
} from '~/components'
import { useApi, useApiEffect } from '~/hooks'
import {
  createDistricts,
  getDistricts,
  getDistrictsNoLimit,
} from '~/services/districts'

import { Container } from './styles'

const ConfigDistricts = () => {
  const [districtList, setDistrictsList] = useState([])
  const [modal, setModal] = useState(false)
  const [search, setSearch] = useState('')
  const { request } = useApi()

  const pagination = useRef()

  useApiEffect(
    getDistrictsNoLimit,
    (response) => {
      setDistrictsList(response.data.content)
      console.log(response.data.content)
    },
    (response) => console.log(response)
  )

  const createDistrict = (data) => {
    request(
      () => createDistricts(data),
      (response) => {
        setSearch('')
        console.log(response.data)
        // window.location.reload()
      },
      () => alert('error')
    )

    setModal(false)
  }

  const districtsListCards = districtList.map((district, i) => {
    const regex = new RegExp(search, 'gi')

    return (
      district.name.match(regex) && (
        <NCardDistrict key={district.id} id={district.id} />
      )
    )
  })

  return (
    <PageBase
      title="Configurações => Áreas"
      content={
        <Container>
          <div className="div_top">
            <NSearch
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Pesquise pelo nome.."
            />
            <NButton
              color="green"
              label="Adicionar Área"
              onClick={() => setModal(true)}
            />
          </div>
          <NModalAddDistrict
            visible={modal}
            onClose={() => setModal(false)}
            label="Adicione uma Área"
            save={(data) => {
              createDistrict(data)
            }}
          />

          {!search ? (
            <Pagination
              ref={pagination}
              service={getDistricts}
              render={(district, i) => (
                <NCardDistrict key={district.id} id={district.id} />
              )}
            />
          ) : (
            districtsListCards
          )}
        </Container>
      }
    />
  )
}

export default ConfigDistricts
