import { object, string } from 'yup'

export const fields = {
  name: {
    label: 'Nome:',
    type: 'text',
  },
  city: {
    label: 'Cidade:',
    type: 'text',
  },
  latitude: {
    label: 'Latitude:',
    type: 'number',
    step: 0.0000001,
  },
  longitude: {
    label: 'Longitude:',
    type: 'number',
    step: 0.0000001,
  },
  radius: {
    label: 'Radius:',
    type: 'number',
    step: 'any',
  },
}

export const schema = object({
  name: string().required('Preencha esse campo!'),
  //city: string(),
  //latitude: string(),
  //longitude: string(),
}).required()
