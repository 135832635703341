import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from '@react-google-maps/api'
import { PageBase } from '~/components'
import { useApiEffect } from '~/hooks'
import { getDriversNoLimit } from '~/services/drivers'
import { Container } from './styles'
import { FaWhatsapp } from 'react-icons/fa'

const MapScreen = () => {
  const [markerIndex, setMarkerIndex] = useState(null)
  const [infoWindowOpen, setInfoWindowOpen] = useState(false)
  const [peopleOnline, setPeopleOnline] = useState([])
  const [selectedLocation, setSelectedLocation] = useState({
    lat: -2.530705,
    lng: -44.297935,
  })
  const message = 'Olá, motorista eu vim pelo site weptek.com'

  const mapRef = useRef(null)

  const sendMessageWhatsapp = useCallback(
    (phoneNumber) => {
      const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
        message
      )}`
      window.open(url, '_blank')
    },
    [message]
  )

  useApiEffect(getDriversNoLimit, (response) => {
    const onlineDrivers = response.data.content.filter(
      (driver) => driver.online
    )
    setPeopleOnline(onlineDrivers)
  })

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBpQhe1eGzN2AzNoOSkpVlB9h48EXG4o8s',
  })

  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, [])

  useEffect(() => {
    if (mapRef.current && peopleOnline.length > 0) {
      const bounds = new window.google.maps.LatLngBounds()
      peopleOnline.forEach((driver) => {
        bounds.extend({
          lat: Number(driver.latitude),
          lng: Number(driver.longitude),
        })
      })
      mapRef.current.fitBounds(bounds, {
        top: 50,
        bottom: 50,
        left: 50,
        right: 50,
      }) // Aplica padding para reduzir zoom
    }
  }, [peopleOnline])

  const imgPurple = require('../../assets/maps-circle-purple.png')

  const handleMarkerClick = useCallback((index) => {
    setInfoWindowOpen(true)
    setMarkerIndex(index)
  }, [])

  const handleCloseInfoWindow = useCallback(() => {
    setInfoWindowOpen(false)
    setMarkerIndex(null)
  }, [])

  const infoWindowStyle = useMemo(
    () => ({
      background: '#fff',
      padding: '10px',
      borderRadius: '5px',
      maxWidth: '260px',
    }),
    []
  )

  if (loadError) return 'Error'
  if (!isLoaded) return 'Loading Maps...'

  return (
    <PageBase
      title="Mapa"
      content={
        <Container>
          <div style={{ height: '90vh', width: '100%' }}>
            <GoogleMap
              mapContainerStyle={{ height: '100%' }}
              center={selectedLocation}
              zoom={13}
              onLoad={onMapLoad}
            >
              <div
                style={{
                  width: 380,
                  height: 60,
                  backgroundColor: 'white',
                  zIndex: 9999,
                  position: 'absolute',
                  top: 50,
                  right: 60,
                  display: 'flex',
                  justifyContent: 'space-around',
                  borderRadius: 14,
                  padding: 2,
                }}
              >
                <InfoBox
                  label="Motoristas online"
                  count={peopleOnline.length}
                  color="#944BBB"
                />
                <InfoBox label="Chamados pendentes" count="-" color="#E50707" />
                <InfoBox
                  label="Chamados em andamento"
                  count="-"
                  color="#22F100"
                />
              </div>

              {peopleOnline.map((data, index) => (
                <MarkerF
                  key={index}
                  position={{
                    lat: Number(data.latitude),
                    lng: Number(data.longitude),
                  }}
                  icon={{
                    url: data.photo || imgPurple,
                    scaledSize: new window.google.maps.Size(50, 50),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(25, 25),
                    shape: {
                      coords: [25, 25, 25],
                      type: 'circle',
                    },
                  }}
                  onClick={() => handleMarkerClick(index)}
                >
                  {infoWindowOpen && markerIndex === index && (
                    <InfoWindowF
                      position={{
                        lat: Number(data.latitude),
                        lng: Number(data.longitude),
                      }}
                      onCloseClick={handleCloseInfoWindow}
                    >
                      <div style={infoWindowStyle}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderBottom: '1px solid #000',
                            paddingBottom: '6px',
                          }}
                        >
                          <p>{data.name}</p>
                          <button
                            onClick={() => sendMessageWhatsapp(data.phone)}
                          >
                            <FaWhatsapp size={30} />
                          </button>
                        </div>
                        <img
                          src={data.photo || imgPurple}
                          alt="Foto do motorista"
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                            marginTop: 5,
                            objectFit: 'cover',
                            border: '3px solid #fff',
                          }}
                        />
                      </div>
                    </InfoWindowF>
                  )}
                </MarkerF>
              ))}
            </GoogleMap>
          </div>
        </Container>
      }
    />
  )
}

// Componente para exibir os ícones de status no mapa
const InfoBox = ({ label, count, color }) => (
  <div
    style={{
      width: 100,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <div
      style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
    >
      <div
        style={{
          backgroundColor: color,
          height: 25,
          width: 25,
          borderRadius: '50%',
          marginRight: 8,
        }}
      />
      <p>{count}</p>
    </div>
    <p style={{ textAlign: 'center', fontSize: 12 }}>{label}</p>
  </div>
)

export default MapScreen
