import React, { useMemo, useState } from 'react'

import { PageBase, NCardPassenger, NSearch } from '~/components'
import { useApi, useApiEffect } from '~/hooks'
import { getPassengers, getPassengersNoLimit } from '~/services/passengers'
import { Container } from './styles'

const Passengers = () => {
  const [passengers, setPassengers] = useState([])
  const [passengersList, setPassengersList] = useState([])
  const [pages, setPages] = useState([])
  const [search, setSearch] = useState('')

  const csv = useMemo(() => {
    let ret = '"nome","email","telefone"\n'

    passengers.forEach((item) => {
      ret += `"${item.name}","${item.email}","${item.phone}"\n`
    })

    return ret
  }, [passengers])

  const { request } = useApi()

  const attData = (data) => {
    setPassengers(data.content)

    setPages(data.pages)

    setSearch('')
  }

  useApiEffect(
    () => getPassengers(1),
    (response) => attData(response.data)
  )

  useApiEffect(getPassengersNoLimit, (response) =>
    setPassengersList(response.data.content)
  )

  const changePage = (pageNumber) => {
    request(
      () => getPassengers(pageNumber),
      (response) => attData(response.data)
    )
  }

  const passengersCards = passengers.map((driver, i) => (
    <NCardPassenger key={`${pages.current}.${i}`} id={driver.id} />
  ))

  const passengersListCards = passengersList.map((driver, i) => {
    const regex = new RegExp(search, 'gi')

    return (
      driver.name.match(regex) && (
        <NCardPassenger key={`${pages.current}.${i}`} id={driver.id} />
      )
    )
  })

  return (
    <PageBase
      title="Passageiros"
      content={
        <Container>
          <div className="div_top print-hide">
            <NSearch
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Pesquise pelo nome.."
            />
            {/* <NButton label="CVS" /> */}
          </div>
          {!search && passengersCards}
          {search && passengersListCards}

          {pages && pages.total > 1 && !search && (
            <div className="div_next">
              {pages.current > 1 && (
                <button
                  onClick={() => changePage(pages.current - 1)}
                  type="button"
                  className="advanced"
                >
                  {`<`}
                </button>
              )}
              {pages.current < pages.total && (
                <button
                  onClick={() => changePage(pages.current + 1)}
                  type="button"
                  className="advanced"
                >
                  &gt;
                </button>
              )}
            </div>
          )}
          <a
            href={`data:text/csv;charset=utf-8,${encodeURI(csv)}`}
            target="_blank"
            download={'Passageiros.csv'}
            rel="noreferrer"
          >
            Gerar CSV
          </a>
        </Container>
      }
    ></PageBase>
  )
}

export default Passengers
