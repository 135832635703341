import React, { forwardRef, useState } from 'react'
import {
  Container,
  TopArea,
  MidArea,
  BotArea,
  TextTopCustom,
  TextMidCustom,
  PriceCustom,
} from './styles'
import NButton from '../NButton'

const RectangleContratar = ({
  title = 'Criação de logomarca',
  description = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Loreting industry. Loreting industry. Loreting industry. Loreting industry. Loreting industry. Loreting industry. Loreting industry. Loreting industry. Loreting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries',
  price = '65,00',
  textButton = 'Contratar',
  onClickAction = () => {},
}) => {
  return (
    <Container>
      <TopArea>
        <TextTopCustom>{title}</TextTopCustom>
        <PriceCustom>R$ {price}</PriceCustom>
      </TopArea>

      <MidArea>
        <TextMidCustom>{description}</TextMidCustom>
      </MidArea>

      <BotArea>
        <NButton
          style={{ height: 32, width: 180 }}
          type="button"
          color="green"
          label={textButton}
          onClick={onClickAction}
          className="print-hide"
        />
      </BotArea>
    </Container>
  )
}

export default RectangleContratar
