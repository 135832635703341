import React, { useState } from 'react'

import { useApi, useApiEffect } from '~/hooks'
import {
  getAppDashboardData,
  getAppData,
  getAppFinancialData,
  setAppData,
} from '~/services/apps'

import { copyText } from '~/utils/copyText'

import {
  Container,
  ContainerCards,
  ContainerTax,
  ContainerLink,
  ContainerContratar,
  CenterComponent,
} from './styles'
import { PageBase, NInfoCard, NTableCard, NCard } from '~/components'
import RectangleContratar from '~/components/RectangleContratar'

const Divulgacao = () => {
  const [app, setApp] = useState()
  const [financial, setFinancial] = useState()
  const [domain, setDomain] = useState('')
  const [tax, setTax] = useState()

  const [pages, setPages] = useState([])

  const infoDivulgaca = [
    {
      id: 1,
      title: 'Criação de logomarca',
      description:
        'Criamos sua logomarca para sua empresa se posicionar e ganhar mercado.',
      price: '40,00',
      link: 'https://www.asaas.com/c/m4av7l9o3jle6dxw',
    },
    {
      id: 2,
      title: 'Criação de Artes',
      description:
        'Criamos 5 Artes para divigação do seu app nas redes sociais.',
      price: '67,00',
      link: 'https://www.asaas.com/c/wbhhh9nx23cmntm7',
    },
    {
      id: 3,
      title: 'Criação de vídeo',
      description:
        'Criamos  o seu vídeo para divulgar o seu app nas redes sociais.',
      price: '85,00',
      link: 'https://www.asaas.com/c/7b5dl77br8uxcsee',
    },
    {
      id: 4,
      title: 'Gestão de tráfego',
      description:
        'Criamos suas campanhas patrocinadas no facebook e instagram(Não incluso verba de anúncios).',
      price: '299,00/mês',
      link: 'https://www.asaas.com/c/cawia0alrdfpm51y',
    },
    {
      id: 5,
      title: 'Criação de site',
      description:
        'Criamos o seu site com o seu próprio domínio e com a sua marca.',
      price: '79,90/mês',
      link: 'https://www.asaas.com/c/owzs4edo5cim58rk',
    },
    {
      id: 6,
      title: 'Consultoria',
      description:
        'Contrate um especialista para te ajudar a faturar alto rodos os meses.',
      price: '299,90',
      link: 'https://www.asaas.com/c/2f22kdabexlzklh8',
    },
    {
      id: 7,
      title: 'Aplicativo administrativo',
      description:
        'Tenha acesso a nosso aplicativo exclusivo para você acompanhar os seus resultados do seu app.',
      price: '39,90',
      link: 'https://www.asaas.com/c/yxagwszd3sloiie0',
    },
    {
      id: 8,
      title: 'Edição de taxa',
      description:
        'Controle a sua taxa sobre as corridas para faturar mais.',
      price: '29,90',
      link: 'https://www.asaas.com/c/nq6awrejv3sg18zs',
    },
    {
      id: 9,
      title: 'Rede de motoristas',
      description:
        'Tenha acesso a nossa gigantesca rede de motoristas para atender seus clientes em qualquer lugar do Brasil.',
      price: '299,90',
      link: 'https://www.asaas.com/c/u5l4xig6blzekmgv',
    },
    {
      id: 10,
      title: 'Anuncie seu app no Relep',
      description:
        'Com o Relep Atraimos milhares de novos usuarios para o seu app.',
      price: '99,90',
      link: 'https://www.asaas.com/c/1l1p1mx5tyhpgens',
    },
    {
      id: 11,
      title: 'Publicação nas lojas',
      description:
        'Publique o seu app na Play Story ou na App Story.',
      price: '2.999,90',
      link: 'https://www.asaas.com/c/8kl7foge9iwdk735',
    },
    {
      id: 12,
      title: 'Crie seu CNPJ',
      description:
        'Formalize a sua empresa de mobilidade com um CNPJ.',
      price: '2.999,90',
      link: 'https://www.asaas.com/c/crraz0m4f5e64lrc',
    },
    {
      id: 13,
      title: 'Domínio personalizado',
      description:
        'Tenha um domínio com a sua marca para fortalecer o seu Branding.',
      price: '150,00/ano',
      link: 'https://www.asaas.com/c/okhj9v0m9cjmq3hs',
    },
    {
      id: 14,
      title: 'Curso de mobilidade',
      description:
        'Um curso com 4 aulas para você ter sucesso com  seu app de mobilidade.',
      price: '99,90',
      link: 'https://www.asaas.com/c/yuvb3x9csnpxphkz',
    },
  ]

  const attData = (data) => {
    setFinancial(data)
    setPages(data.billings.pages)
    console.log(pages)
  }

  const changePage = (pageNumber) => {
    request(
      () => getAppFinancialData(pageNumber),
      (response) => attData(response.data)
    )
  }

  const { request } = useApi()

  useApiEffect(getAppDashboardData, (response) => {
    setApp(response.data)
  })

  useApiEffect(getAppData, (response) => {
    setDomain(response.data.domain)
  })

  useApiEffect(
    () => getAppFinancialData(1),
    (response) => {
      attData(response.data)
    }
  )

  const attFinancialData = () => {
    request(getAppFinancialData, (response) => setFinancial(response.data))
  }

  const changeTax = () => {
    if (tax <= 40)
      request(
        () => setAppData({ tax: Number(tax) / 100 }),
        () => attFinancialData()
      )
    else {
      alert('A taxa do licensiado não pode ultrapassar os 40%')
      setTax(financial.taxes.app.split('%')[0])
    }
  }

  return (
    <PageBase
      title="Divulgação"
      content={
        <Container>
          {/* Comentar link do domínio */}
          {/*
          {domain && domain !== 'default' && (
            <ContainerLink>
              <a
                href={`https://weptek.app/${domain}`}
                target="_blank"
                rel="noreferrer"
              >
                weptek.app/{domain}
              </a>
              <MdContentCopy
                size={25}
                className="icon_link"
                onClick={() => copyText(`https://weptek.app/${domain}`)}
              />
            </ContainerLink>
          )}
          */}

          {/* Comentar RectangleSaibaMais */}
          {/*
          <RectangleSaibaMais
            title="Conheça os nossos serviços disponívies para alavancar os resultados
          do seu app"
            textButton="Saiba mais"
          />
          */}

          {/* Renderizar apenas os serviços */}
          <ContainerContratar>
            {infoDivulgaca.map((data) => (
              <RectangleContratar
                key={data.id}
                title={data.title}
                description={data.description}
                price={data.price}
                onClickAction={() => window.open(data.link, '_blank')}
              />
            ))}
          </ContainerContratar>
        </Container>
      }
    />
  )
}

export default Divulgacao

