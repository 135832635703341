import { useEffect, useState } from 'react'
import { PageBase } from '~/components'
import NCardCategory from '~/components/NCardCategory'
import { api } from '~/services/api'
import { Container } from './styles'

const ConfigCategories = () => {
  const [categories, setCategories] = useState([])

  async function fetchCategories() {
    const res = await api.get('/licensed-categories')

    setCategories(res.data.content)
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  console.log(categories)

  return (
    <PageBase
      title="Configurações => Categorias"
      content={
        <Container>
          {categories.map((value, index) => {
            return <NCardCategory key={index} data={value} />
          })}
        </Container>
      }
    ></PageBase>
  )
}

export default ConfigCategories
