import styled from 'styled-components'

export const Container = styled.form`
  display: flex;
  padding: 0 5% 20px 5%;

  width: 100%;

  flex-direction: column;

  > .left {
    max-width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
      max-width: 100%;
    }
  }

  > .right {
    margin-top: 25px;

    display: flex;
    flex-direction: column;

    gap: 50px;
  }
`

export const Passenger = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${({ status, theme }) =>
      status === 'finished' ? theme.colors.green : theme.colors.red};
  }

  p {
    max-width: calc(100% - 20px);
  }
`
