import { Route, Routes } from 'react-router-dom'

import {
  ConfigApp,
  ConfigDistricts,
  ConfigRoutes,
  Drivers,
  Passengers,
  Principal,
  Trips,
  Charges,
  Divulgacao,
} from '~/pages'
import ConfigCategories from '~/pages/ConfigCategories'
import MapScreen from '~/pages/Mapa'
import PremiumScreen from '~/pages/Premium'

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Principal />} />
      <Route path="/motoristas" element={<Drivers />} />
      <Route path="/passageiros" element={<Passengers />} />
      <Route path="/cobrancas" element={<Charges />} />
      <Route path="/categorias" element={<ConfigCategories />} />
      <Route path="/rotas" element={<ConfigRoutes />} />
      <Route path="/divulgacao" element={<Divulgacao />} />
      <Route path="/bairros" element={<ConfigDistricts />} />
      <Route path="/viagens/em-andamento" element={<Trips.Running />} />
      <Route path="/viagens/finalizadas" element={<Trips.Finished />} />
      <Route path="/viagens/canceladas" element={<Trips.Canceled />} />
      <Route path="/personalizacao" element={<ConfigApp />} />
      <Route path="/mapa" element={<MapScreen />} />
      <Route path="/premium" element={<PremiumScreen />} />
    </Routes>
  )
}

export default App
