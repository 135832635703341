import { api, apiForm } from '~/services/api'

export const getPassengers = (page) => api.get(`/passengers?page=${page}`)
export const getPassengersNoLimit = () => api.get(`/passengers?limit=null`)
export const getPassengersId = (id) => api.get(`/passengers/${id}`)

export const enablePassenger = (id) => api.put(`/passengers/${id}/enable`)
export const disablePassenger = (id) => api.put(`/passengers/${id}/disable`)

export const setPassengerForceForm = (id, data) =>
  apiForm.put(`/passengers/${id}/force`, data)
