import styled from 'styled-components'

export const Wrapper = styled.form`
  padding: 40px 0 20px;

  background: white;
  width: 100%;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Container = styled.div`
  margin: 0 0 30px;

  width: 100%;

  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }

  .left {
    width: 100%;

    ${({ theme }) => theme.media.lg} {
      width: 50%;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 10px;

    .logo {
      margin: 10px auto 20px;
      width: 100%;

      display: flex;
      justify-content: center;
    }

    p,
    u {
      font-size: 16px;
    }

    .input-wrapper {
      width: calc(100% - 36px);

      ${({ theme }) => theme.media.md} {
        width: 70%;
      }
    }

    input {
      width: 100% !important;
      max-width: 10000px !important;
    }
  }

  .separator {
    margin: 20px 0;

    width: 70%;
    height: 1px;
    background: ${({ theme }) => theme.colors.gray};

    align-self: center;

    ${({ theme }) => theme.media.lg} {
      width: 1px;
      height: 100%;
    }
  }

  .right {
    width: 100%;

    ${({ theme }) => theme.media.lg} {
      width: 50%;
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    .domain {
      margin: 0 0 10px;
    }

    .name {
      margin: 0 0 30px;

      font-family: 'Comfortaa';
      font-size: 20px;
      text-align: center;

      color: #ffffff;
    }

    .preview-container {
      padding: 40px 10px 20px;

      width: 50%;

      background: ${({ primary }) => primary};

      display: flex;
      flex-direction: column;
      align-items: center;

      > img {
        width: auto;
        height: 70px;
      }

      .preview {
        width: 100%;
        height: auto;
      }
    }

    .primary-fill {
      fill: ${({ primary }) => primary};
    }
  }
`