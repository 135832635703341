import styled from 'styled-components'

export const Container = styled.div`
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 30px;
  border-radius: 20px;
  height: ${({ opened }) => (opened ? `100%` : '70px')};

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .fixed-price {
    margin-top: 12px;
    display: flex;
    gap: 16px;
  }
`
export const Top = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin-left: 10px;
    font-size: 17px;
  }
`

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const Field = styled.div`
  width: 100%;
  max-width: 150px;
  margin: 20px;
`

export const Title = styled.div`
  height: 45px;
  font-size: 17px;
`

export const Input = styled.input`
  width: 150px;
  height: 55px;
  border-radius: 10px;
  color: white;
  border: 0;
  outline: 0;
  padding: 20px;

  ::placeholder {
    color: white;
  }
`

export const Menu = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const Edit = styled.div`
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  font-size: 15px;
`

export const Active = styled.div`
  width: 200px;
  height: 40px;
  background-color: #22f100;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  font-size: 15px;
`
