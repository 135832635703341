import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 420px;
  padding: 24px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-4px);
  }
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

export const WrapperIdDotColor = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusDot = styled.div`
  background-color: ${(props) => props.color || '#a5a5a5'};
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const TextHeader = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #333333;
`;

export const DateText = styled.span`
  font-size: 12px;
  color: #888888;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 12px 0;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

