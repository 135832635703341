import styled from 'styled-components'

export const Container = styled.form``

export const ContainerRowSaibaMais = styled.div`
  padding: 4px 24px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  .textNormal {
    font-size: 18px;
    font-weight: 700;
    min-width: 100px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .textNormal {
      font-size: 14px;
      font-weight: 700;
    }
    input {
      min-width: 100px;
    }

    button {
      max-width: 180px;
      word-break: break-word;
      min-width: 100px;
    }
  }
`
