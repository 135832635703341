import styled from 'styled-components'
import { theme } from '~/styles';

export const InputStyled = styled.input`
  width: ${(props) => props.width ? props.width : '180px'};
  height: 41px;
  border-radius: 8px;
  border: none;
  padding: 8px;
  color: ${(props) => props.color ? props.color : theme.colors.light_gray};
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : theme.colors.dark};
text-align: ${(props) => props.txtAlign ? props.txtAlign : 'center'};


   ::placeholder {
    color: #aaa;
  }
`;
