import { getAppId } from '~/repositories/app'
import { admin, adminForm } from '~/services/api'

export const createApp = (data, key) =>
  adminForm.post('/apps', data, {
    headers: {
      'X-Admin-Permission-Token': key,
    },
  })

export const updateApp = (data) => adminForm.put(`/apps/${getAppId()}`, data)

export const verifyAppData = (data) => admin.post('/apps/verify-data', data)

export const getAppData = () => admin.get(`/apps/${getAppId()}`)

export const setAppData = (data) => admin.put(`/apps/${getAppId()}`, data)

export const getAppDashboardData = () =>
  admin.get(`/apps/${getAppId()}/dashboard`)

export const getAppFinancialData = (page) =>
  admin.get(`/apps/${getAppId()}/financial?page=${page}`)

export const computePayment = (id) =>
  admin.post(`/apps/${getAppId()}/financial/${id}/compute-payment/`)
