import React from 'react'
import { BsPersonCircle } from 'react-icons/bs'
import {
  Container,
  TextName,
  WrapperPhotoNameRating,
  WrapperPhotoName,
  TextComment,
} from './styles'

import smileLove from '~/assets/smiles/love.png'
import smileHappy from '~/assets/smiles/feliz.png'
import smileNormal from '~/assets/smiles/indiferente.png'
import smileSad from '~/assets/smiles/triste.png'
import smileHate from '~/assets/smiles/bravo.png'

const CardCommentsPeoples = ({ data }) => {
  const showRatingSmile = (rating) => {
    switch (rating) {
      case 10:
        return <img src={smileLove} width={30} height={30} alt="smileLove" />
      case 5:
        return <img src={smileHappy} width={30} height={30} alt="smileHappy" />
      case 0:
        return <img src={smileNormal} width={30} height={30} alt="smileNormal" />
      case -5:
        return <img src={smileSad} width={30} height={30} alt="smileSad" />
      case -10:
        return <img src={smileHate} width={30} height={30} alt="smileHate" />
      default:
        return (
          <div
            style={{
              backgroundColor: '#a5a5a5',
              height: 30,
              width: 30,
              borderRadius: '50%',
            }}
          />
        )
    }
  }

  return (
    <>
      {data?.driverData ? (
        <Container style={{ backgroundColor: '#f0f0f0' }}>
          <WrapperPhotoNameRating>
            <WrapperPhotoName style={{ display: 'flex', alignItems: 'center' }}>
              {data?.driverData?.photo ? (
                <img
                  src={data?.driverData?.photo}
                  width={30}
                  height={30}
                  alt="premiumImg"
                  style={{ borderRadius: '50%' }}
                />
              ) : (
                <BsPersonCircle size={30} />
              )}
              <TextName style={{ marginLeft: 8 }}>{data?.driverData?.name}</TextName>
            </WrapperPhotoName>
            <div style={{ marginLeft: 'auto' }}>{showRatingSmile(data?.driverData?.rating)}</div>
          </WrapperPhotoNameRating>
          <div style={{ marginTop: 18 }} />
          <TextComment>{data?.driverData?.driverComment || '-----'}</TextComment>
        </Container>
      ) : (
        <p>Sem dados de motorista</p>
      )}

      <Container style={{ backgroundColor: '#f8f8f8' }}>
        <WrapperPhotoNameRating>
          <WrapperPhotoName style={{ display: 'flex', alignItems: 'center' }}>
            {data?.photo ? (
              <img
                src={data?.photo}
                width={30}
                height={30}
                alt="premiumImg"
                style={{ borderRadius: '50%' }}
              />
            ) : (
              <BsPersonCircle size={30} />
            )}
            <TextName style={{ marginLeft: 8 }}>{data?.name}</TextName>
          </WrapperPhotoName>
          <div style={{ marginLeft: 'auto' }}>{showRatingSmile(data?.rating)}</div>
        </WrapperPhotoNameRating>
        <div style={{ marginTop: 18 }} />
        <TextComment>{data?.passengerComent || '-----'}</TextComment>
      </Container>
    </>
  )
}

export default CardCommentsPeoples

