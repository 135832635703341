import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Container, Arrow } from './styles'
import { IoMdArrowDropright } from 'react-icons/io'
import { useAuth } from '~/hooks'
import ModalPremium from '../ModalPremium'

function NSidebarItem({
  route = '',
  closeSidebar = () => null,
  icon,
  label = '',
  arrow,
  arrowBottom,
  ...rest
}) {
  const { user } = useAuth()

  const [mouse, setMouse] = useState(false)
  const [hasPremium, setHasPremium] = useState(true)
  const [visibleModal, setVisibleModal] = useState(false)

  const onCancel = () => {
    setVisibleModal(false)
  }

  return (
    <Container mouse={mouse} {...rest}>
      {visibleModal && (
        <ModalPremium
          visible={visibleModal}
          label="Você tem certeza?"
          onCancel={() => onCancel()}
          confirmLabel="Sim, confimar"
          cancelLabel="Não, cancelar"
          onClose={() => onCancel()}
        />
      )}
      {icon}

      {
        // !user?.app?.accountPremium
        !hasPremium && route === '/mapa' ? (
          <Link className="option" onClick={() => setVisibleModal(true)}>
            <p className="option">{label}</p>
          </Link>
        ) : (
          <Link
            to={route}
            className="option"
            onClick={() => closeSidebar()}
            onMouseEnter={() => setMouse(true)}
            onMouseLeave={() => setMouse(false)}
          >
            <p className="option">{label}</p>
          </Link>
        )
      }

      {arrow && (
        <Arrow close={arrowBottom}>
          <IoMdArrowDropright size={20} />
        </Arrow>
      )}
    </Container>
  )
}

export default NSidebarItem
