import React from 'react'
import { useForm } from 'react-hook-form'

import { NButton, NInput } from '~/components'
import { useApi } from '~/hooks'
import { setAppId } from '~/repositories/app'
import { setAuth } from '~/repositories/auth'
import { checkSubscription, login } from '~/services/auth'
import Modal from 'react-modal'
import { Container } from './styles'
import { IoClose } from 'react-icons/io5'

const Form = () => {
  const { request } = useApi()

  const { register, handleSubmit, setError, formState } = useForm()

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  }

  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [invoiceUrlValeu, setInvoiceUrlValue] = React.useState('')
  const [descriptionModal, setDescriptionModal] = React.useState('')

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const onSubmit = (data) => {
    data.phone = data.phone.replace(/\D/g, '')

    request(
      () => login(data),
      ({ data }) => {
        checkSubscription(data.credential.accountId)
          .then((payload) => {
            if (!payload.pendingPayments) {
              setAuth(data.credential)
              setAppId(data.app.id)

              window.location.reload()
            } else {
              setInvoiceUrlValue(payload?.invoiceUrl)
              setDescriptionModal(
                'Identificamos pagamento pendente da sua assinatura! Abra o link da cobrança no botão abaixo:'
              )
              openModal()
            }
          })
          .catch(() => {
            setInvoiceUrlValue('https://www.asaas.com/c/259414883360')

            setDescriptionModal(
              'O tempo grátis para uso da dashboard acabou! Realize o pagamento da sua assinatura e lembre-se de colocar os mesmos dados que usou para cadastro nesse painel. Assine através do link; www.weptek.com.br/assinar'
            )
            openModal()
          })
      },
      () => setError('password', { message: 'Telefone ou senha incorretos!' })
    )
  }

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <button onClick={closeModal}>
              <IoClose size={30} />
            </button>
          </div>

          <p style={{ fontSize: 20 }}>{descriptionModal}</p>

          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: 18,
            }}
          >
            <button
              onClick={() =>
                invoiceUrlValeu
                  ? window.open(invoiceUrlValeu)
                  : alert('URL Não encontrada')
              }
              style={{
                height: 45,
                width: 240,
                backgroundColor: '#944BBB',
                borderRadius: 50,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p style={{ fontSize: 20, color: '#FFF' }}>Ver mais </p>
            </button>
          </div>
        </div>
      </Modal>

      <NInput
        light
        placeholder="Telefone"
        type="phone"
        {...register('phone')}
        error={formState.errors.phone?.message}
        errorOutline={formState.errors.password}
        mask="+55 (99) 99999-9999"
      />
      <NInput
        light
        placeholder="Senha"
        type="password"
        {...register('password')}
        error={formState.errors.password?.message}
      />
      <NButton className="button" label="Entrar" outline type="submit" />
    </Container>
  )
}

export default Form
