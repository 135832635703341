import React, { useState, useImperativeHandle, forwardRef } from 'react'

import { useApi, useApiEffect } from '~/hooks'

import { Container } from './styles'

const Pagination = ({ service, render }, ref) => {
  const [data, setData] = useState({ content: [], pages: {} })

  const { request } = useApi()

  const changePage = (pageNumber) => {
    request(
      () => service(pageNumber),
      (response) => setData(response.data)
    )
  }

  useApiEffect(
    () => service(1),
    (response) => setData(response.data),
    (response) => console.log(response)
  )

  const updateData = () => {
    request(
      () => service(1),
      (response) => setData(response.data),
      (response) => console.log(response)
    )
  }

  useImperativeHandle(ref, () => ({
    refresh: updateData,
  }))

  return (
    <Container>
      {data.content.map(render)}
      <div className="div_next">
        {data.pages.current > 1 && (
          <button
            onClick={() => changePage(data.pages.current - 1)}
            type="button"
            className="advanced"
          >
            {`<`}
          </button>
        )}
        {data.pages.current < data.pages.total && (
          <button
            onClick={() => changePage(data.pages.current + 1)}
            type="button"
            className="advanced"
          >
            &gt;
          </button>
        )}
      </div>
    </Container>
  )
}

export default forwardRef(Pagination)
